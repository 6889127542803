import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { catchError, take } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from '~environments/environment';
import { map } from 'rxjs/operators';
import { SessionDispatchers, SessionSelectors, AnalyticsDispatchers } from '~app/store';
import { SessionData } from '~app/models/sessiondata.model';
import { AuthTokenSelectors } from '~app/store/services/auth-token/auth-token.selectors';
import { StationCode } from '~app/models/stationCode.model';
import { Base64Service, CurrentLocaleService } from '@hawaiianair/core';
import { departureCityPricingMap } from '~app/models/pricing.model';
import { CorrelationContainerService } from '@hawaiianair/common';
import { LaunchDarklyClientService } from '~app/services/launch-darkly-client.service';
import { FeatureFlagConstants } from '~app/constants/feature-flag-constants';

@Injectable()
export class CheckInInterceptor implements HttpInterceptor {
  stationCode$: Observable<StationCode>;
  stationCode: StationCode;
  sessionData$: Observable<SessionData>;
  sessionData: SessionData;
  authToken$: Observable<string>;
  isBasicAuth = false;
  basicAuthEndpoints = ["/flights", "/tripauth", "/referenceData", "/idVerification"];
  private departureCityPricingMap = departureCityPricingMap;

  constructor(
    private sessionDispatchers: SessionDispatchers,
    private sessionSelectors: SessionSelectors,
    private authTokenSelectors: AuthTokenSelectors,
    private analyticsDispatchers: AnalyticsDispatchers,
    private localeService: CurrentLocaleService,
    private correlationContainer: CorrelationContainerService,
    private base64Service: Base64Service,
    private ldClientService: LaunchDarklyClientService,
  ) {
    this.sessionData$ = this.sessionSelectors.session$;
    this.authToken$ = this.authTokenSelectors.authToken$;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isExpApiCall = request.url.includes(environment["exp-web-checkin-v2-api"]);
    const isMissingRegDetailsCall = request.url.includes('missingRegulatoryDetails');
    this.isBasicAuth = this.basicAuthEndpoints.some(endpoint => request.url.includes(endpoint));
    this.sessionData$.pipe(take(1)).subscribe(sessionData => this.sessionData = sessionData);

    let headers = new HttpHeaders();

    if (isExpApiCall) {
      const correlationId = `WCI-${this.correlationContainer.generateNewId()}`;
        headers = new HttpHeaders()
        .set('x-root-correlation-id', correlationId)
        .set('x-correlation-id', correlationId)
        .set('x-ha-channel', 'WEB')
        .set('x-ha-business-domain', 'CHECKIN')
        .set('x-ha-pss', '1A')
        .set('x-user-agent', 'Hawaiian-Airline Onboarding/v1.0')
        .set('x-ha-currency', this.departureCityPricingMap[this.localeService.locale].currencyCode)
        .set('x-ha-pcc', this.departureCityPricingMap[this.localeService.locale].pcc)
        .set('x-ha-city-code', this.departureCityPricingMap[this.localeService.locale].pcc);

      headers = this.isBasicAuth
        ? headers.set('Authorization', `Basic ${this.base64Service.btoa(environment["expWebCheckinApiAuth"])}`)
        : headers.set('Authorization', this.sessionData.authToken)

      //get missing details failing when called with content type, TODO: find out why 
      !isMissingRegDetailsCall && (headers = headers.set('Content-Type', 'application/json'));
      
      this.sessionDispatchers.setSessionCorrelationId(headers.get('x-root-correlation-id'));
      let url = request.url;
      
      if (this.ldClientService.getFlag(FeatureFlagConstants.WEB_CHECK_IN_SPA_INT1_ENABLED) && environment?.["exp-web-checkin-int1-v2-api"] && !url.includes('referenceData')) {
        url = request.url.replace(environment["exp-web-checkin-v2-api"], environment["exp-web-checkin-int1-v2-api"]);
        headers = headers.set('Authorization', this.isBasicAuth ? `Basic ${this.base64Service.btoa(environment?.['exp-web-checkin-int1-v2-api-auth'])}` :
          this.sessionData.authToken);
      }

      request = request.clone({ headers, url });
      this.analyticsDispatchers.logRequestData(request);
    }
    return next.handle(request).pipe(
      map(res => {
        if (res instanceof HttpResponse && res.body && isExpApiCall && !request.url.includes('seatmap')) {
          res.body.correlationId = headers.get('x-root-correlation-id');
          this.analyticsDispatchers.logResponseData(JSON.parse(JSON.stringify(res)));
        }
        return res;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error && error.error && isExpApiCall) {
          error.error.correlationId = headers.get('x-root-correlation-id');
          this.analyticsDispatchers.logResponseData(JSON.parse(JSON.stringify(error)));
        }
        return throwError(error);
      })
    );
  }
}
