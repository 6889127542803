import * as CartActions from '~app/store/actions/cart.actions';
import { Injectable } from '@angular/core';
import { ConcurrencyModalData, ConcurrencyModalComponent } from './concurrency-modal/concurrency-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { SeatUnavailableModalComponent } from './seat-unavailable-modal/seat-unavailable-modal.component';
import { FlightDelayedModalComponent } from './flight-delayed-modal/flight-delayed-modal.component';
import { DialogModalComponent } from '~app/shared/dialog-modal/dialog-modal.component';
import { PassportScanComponent } from '~app/components/international/passport-information/passport-scan/passport-scan.component';
import { SessionData } from '~app/models/sessiondata.model';
import { Trip } from '~app/models/trip.model';
import { CHECKIN_ROUTES } from '~app/store/reducers/router/checkin-route-serializer';
import { CartDispatchers } from '~app/store/services/cart/cart.dispatchers';
import { RouterDispatchers } from '~app/store/services/router/router.dispatchers';
import { SessionDispatchers } from '~app/store/services/session/session.dispatchers';
import { SessionSelectors } from '~app/store/services/session/session.selectors';
import { Observable, Subscription } from 'rxjs';
import { UpgradeAllPaxModalComponent } from '~app/shared/upgrade-all-pax-modal/upgrade-all-pax-modal.component';
import { AuthTokenExpirationModalComponent } from './auth-token-expiration-modal/auth-token-expiration-modal.component';
import { BagsPriceConfirmationModalComponent } from './bags-price-confirmation-modal/bags-price-confirmation-modal.component';
import { MilitaryTypeEmptyModalComponent } from '~app/modals/military-type-empty-modal/military-type-empty-modal.component';
import { MilitaryTypeSuccessModalComponent } from '~app/modals/military-type-success-modal/military-type-success-modal.component';
import { MilitarySaveTypeComponent } from '~app/modals/military-save-type/military-save-type.component';
import { PassengerFaqComponent } from './passenger-faq/passenger-faq.component';
import { RemoveInfantComponent } from './remove-infant/remove-infant.component';
import { BoardingPassNextStepsModalComponent } from './boarding-pass-next-steps-modal/boarding-pass-next-steps-modal.component';
import { PassportMatchErrorComponent } from './passport-match-error/passport-match-error.component';
import { OffersErrorModalComponent } from '~app/modals/offers-error-modal/offers-error-modal.component';
import { RemoveInfantData } from '~app/models/infantpassengerinfo.model';
import { GenericErrorComponent } from './generic-error/generic-error.component';
import { PartialCartsModalComponent } from './partial-carts-modal/partial-carts-modal.component';
import { PassengerUpdateErrorModalComponent } from './passenger-update-error-modal/passenger-update-error-modal.component';
import { PassengerName } from '~app/models/passengername.model';
import { AddGuestErrorModalComponent } from '~app/modals/add-guest-error-modal/add-guest-error-modal.component';
import { GroupDOBModalComponent } from '~app/modals/group-dob-modal/group-dob-modal.component';
import { InfantAgedOutModalComponent } from '~app/modals/infant-aged-out-modal/infant-aged-out-modal.component';
import { InfantExitSeatUnavailableModalComponent } from '~app/modals/infant-exit-seat-unavailable-modal/infant-exit-seat-unavailable-modal.component';
import { RemoveInfantConfirmationComponent } from '~app/modals/remove-infant-confirmation-modal/remove-infant-confirmation-modal.component';
import { TargetMessageModalComponent } from '~app/modals/target-message-modal/target-message-modal.component';
import { TripDispatchers } from '~app/store/services/trip/trip.dispatchers';
import { LinkMinorModalComponent } from './link-minor-modal/link-minor-modal.component';
import { MinorCheckinErrorModalComponent } from './minor-checkin-error-modal/minor-checkin-error-modal.component';
import { MinorTravelingAloneModalComponent } from './minor-traveling-alone-modal/minor-traveling-alone-modal.component';
import { SeatUpgradeErrorModalComponent } from './seat-upgrade-error-modal/seat-upgrade-error-modal.component';
import { MilitaryReferOutModalComponent } from './military-refer-out-modal/military-refer-out-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalsService {

  sessionData: SessionData;
  sessionData$: Observable<SessionData>;
  private sessionSubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    private cartDispatchers: CartDispatchers,
    private sessionSelectors: SessionSelectors,
    private sessionDispatchers: SessionDispatchers,
    private routerDispatchers: RouterDispatchers,
    private tripDispatchers: TripDispatchers
  ) {
    this.sessionData$ = this.sessionSelectors.session$;
  }

  openNextStepsDialog(bagsToCheckIn: boolean): void {
    this.dialog.open(BoardingPassNextStepsModalComponent, {
      data: bagsToCheckIn
    });
  }

  openCheckInInProgressDialog(actionType: string, route?: string): void {
    if (this.dialog.openDialogs.some(dialog => dialog.id === 'offers-error-modal')) { return; }

    this.sessionSubscription = this.sessionData$.subscribe(
      session => {
        if (!!session) {
          this.sessionData = session;
        } else {
          this.sessionDispatchers.getState();
        }
      }
    );

    const dlgData: ConcurrencyModalData = {
      isPaymentInProgress: false,
      isCheckInInProgress: true,
      isSessionEnded: false,
      passengerNames: this.getPassengerNames(),
    };

    const dialogRef = this.dialog.open(ConcurrencyModalComponent, {
      disableClose: true,
      data: dlgData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (actionType === CartActions.createCartPassengers.type ||
          actionType === CartActions.createCartDashboard.type) {
          this.cartDispatchers.recreateCartPassengers(
            this.sessionData.cartId,
            this.sessionData.trip2Checkin.confirmationCode,
            this.sessionData.selectedSegment.id,
            this.sessionData.passengerIds.join(),
            route
          );
        }
      } else {
        this.routerDispatchers.routeToCheckin('Concurrency Modal Component');
      }
      if (this.sessionSubscription) {
        this.sessionSubscription.unsubscribe();
      }
    });
  }

  openSessionEndedConcurrencyModal(): void {
    const dlgData: ConcurrencyModalData = {
      isPaymentInProgress: false,
      isCheckInInProgress: false,
      isSessionEnded: true
    };

    const dialogRef = this.dialog.open(ConcurrencyModalComponent, {
      disableClose: true,
      data: dlgData,
    });

    dialogRef.afterClosed().subscribe(_ => {
      this.routerDispatchers.routeToCheckin('Concurrency Modal Component');
    });
  }

  openPaymentInProgressDialog(actionType: string): void {
    const dlgData: ConcurrencyModalData = {
      isPaymentInProgress: true,
      isCheckInInProgress: false,
      isSessionEnded: false
    };
    const dialogRef = this.dialog.open(ConcurrencyModalComponent, {
      disableClose: true,
      data: dlgData,
    });

    dialogRef.afterClosed().subscribe(_ => {
      this.routerDispatchers.routeToCheckin('Concurrency Modal Component');
    });
  }

  openSessionEndedDialog(): void {
    this.dialog.open(AuthTokenExpirationModalComponent, {
      disableClose: true // Required for user confirmation
    });
  }

  openBagPriceConfirmationModal(): void {
    this.dialog.open(BagsPriceConfirmationModalComponent, {
      data: true,
    });
  }

  openUpdatePassengerErrorMessage(error: any, from: string, routeTo: string = 'passenger'): void {
    this.dialog.open(PassengerUpdateErrorModalComponent, {
      data: {
        error,
        from,
        routeTo
      }
    });
  }

  openEmptyMilitaryTypeMessage(tripId: string, passengerData: any, routeTo: string): void {
    this.dialog.open(MilitaryTypeEmptyModalComponent, {
      data: {
        tripId: tripId,
        passengerData,
        routeTo
      },
    });
  }

  openMilitaryTypeSuccessMessage(routeTo: string): void {
    this.dialog.open(MilitaryTypeSuccessModalComponent, {
      data: {
        routeTo,
      },
    });
  }

  openMilitarySaveTypeMessage(tripId: string, passengerData: any, routeTo: string): void {
    this.dialog.open(MilitarySaveTypeComponent, {
      data: {
        tripId: tripId,
        passengerData,
        routeTo
      },
    });
  }

  openSeatUnavailableDlg() {
    const dialogRef = this.dialog.open(SeatUnavailableModalComponent, {});
    dialogRef.afterClosed().subscribe(_ => {
      this.cartDispatchers.cartErrorAccepted();
    });
  }

  openInfantExitSeatUnavailableModal(): void {
    const dialogRef = this.dialog.open(InfantExitSeatUnavailableModalComponent);
    this.dialog.open(InfantExitSeatUnavailableModalComponent, {
      data: true
    });

    dialogRef.afterClosed().subscribe(_ => {
      this.routerDispatchers.routeToInfant('Infant Exit Seat Unavailable Modal');
    });
  }

  private getPassengerNames(): string[] {
    return this.sessionData && this.sessionData.passengerIds.map(
      passengerId => {
        const theDetail = this.sessionData.selectedSegment.details.find(detail => detail.passengerId === passengerId);
        if (!!theDetail) {
          return `${theDetail.passengerName.firstName} ${theDetail.passengerName.lastName}`;
        }
      }
    ).filter(pax => !!pax);
  }

  openFlightDelayed(from: string, to: string, EDT: Date): void {
    this.dialog.open(FlightDelayedModalComponent, {
      data: {
        origin: from,
        destination: to,
        estimatedDepartureTime: EDT
      },
    });
  }

  openPartialSuccessModal(rangeReferences: string[]) {
    this.dialog.open(PartialCartsModalComponent, {
      data: rangeReferences,
      disableClose: true
    });
  }

  openAddGuestErrorModal() {
    this.dialog.open(AddGuestErrorModalComponent, {
      data: true
    });
  }

  openUpgradeAllSeatsDialog() {
    this.dialog.open(UpgradeAllPaxModalComponent, {
      disableClose: true,
      data: true
    });
  }

  openMinorCheckinErrorModal(errorType: string) {
    const dialogRef = this.dialog.open(MinorCheckinErrorModalComponent, {
      data: errorType
    });

    dialogRef.afterClosed().subscribe(() =>
      this.routerDispatchers.routeToCheckin('MinorCheckinErrorModalComponent')
    );
  }

  // opens generic error message modal
  openGenericErrorMessage(dataError: any, allowMultipleModals: boolean = false): void {
    // prevents displaying multiple error dialogs
    if (allowMultipleModals || this.dialog.openDialogs.length === 0) {
      this.dialog.closeAll();
      const dialogRef = this.dialog.open(GenericErrorComponent, {
        disableClose: true,
        data: dataError,
      });

      dialogRef.afterClosed().subscribe(response => {
        if (response) {
          this.routerDispatchers.routeToCheckin('Generic Error Modal Component');
        }
      });
    }
  }

  // remove infant error modal
  openRemoveInfantErrorModal(error: any): void {
    this.dialog.open(GenericErrorComponent, {
      disableClose: true,
      data: {
        err: error,
        contentProps: 'messageRemoveInfantError'
      }
    });
  }

  openInfantAgedOutModal(): void {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(InfantAgedOutModalComponent, {
      data: true
    });
    dialogRef.afterClosed().subscribe(() => {
      this.routerDispatchers.routeToPassenger('Infant Aged Out Modal Component');
    });
  }

  openQuitModal() {
    const dialogRef = this.dialog.open(DialogModalComponent, {
      disableClose: true,
      data: 'quit-modal',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        this.sessionSubscription = this.sessionData$.subscribe(
          session => {
            if (!!session) {
              this.sessionData = session;
            }
          }
        );
        if (this.sessionData && this.sessionData.cartId) {
          this.cartDispatchers.deleteCart(this.sessionData.cartId);
        }
        this.routerDispatchers.routeToCheckin("header");
        if (this.sessionSubscription) {
          this.sessionSubscription.unsubscribe();
        }
      }
    });
  }

  openCheckinConfirmModal() {
    const dialogRef = this.dialog.open(DialogModalComponent, {
      data: 'checkin-confirm-modal'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.routerDispatchers.routeToGuidelines(CHECKIN_ROUTES.ROUTE_PASSENGER.component);
      }
    });
  }

  // opens frequent flyer info modal
  openFAQ(showHM: boolean): void {
    this.dialog.open(PassengerFaqComponent, {
      ariaLabel: "Frequent Flyer Information",
      data: showHM
    });
  }

  // opens Infant Removal Modal
  openInfantRemoval(trip: Trip): void {
    this.dialog.open(RemoveInfantComponent, {
      disableClose: true,
      data: { results: trip }
    });
  }

  openRemoveInfantFromPaxCardModal(removeInfantData: RemoveInfantData): void {
    this.dialog.open(RemoveInfantConfirmationComponent, {
      disableClose: true,
      data: removeInfantData
    });
  }

  // opens Scan Passport modal
  openScanPassport(paxId: string): void {
    this.dialog.open(PassportScanComponent, {
      autoFocus: false,
      disableClose: true,
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '100%',
      height: '100%',
      data: { paxId }
    });
  }

  // opens Rescan Passport error modal
  openRescanPassportModal(data: any): void {
    this.dialog.closeAll();
    this.dialog.open(GenericErrorComponent, {
      disableClose: true,
      data: data
    });
  }

  // opens Rescan Passport error modal
  openPassportMatchErrorModal(data: any): void {
    this.dialog.closeAll();
    this.dialog.open(PassportMatchErrorComponent, {
      data: data
    });
  }

  // opens restricted Airports error message modal
  openRestrictedAirportsErrorMessage(dataError: any): void {
    // prevents displaying multiple error dialogs
    if (this.dialog.openDialogs.length === 0) {
      this.dialog.open(GenericErrorComponent, {
        disableClose: true,
        data: dataError,
      });
    }
  }

  openOffersErrorModal(): void {
    this.dialog.closeAll();
    this.dialog.open(OffersErrorModalComponent, {
      id: 'offers-error-modal'
    });
  }

  openGroupDOBModal(tripId: string, confirmationCode: string, passengerName: PassengerName): void {
    const dialogRef = this.dialog.open(GroupDOBModalComponent, {
      data: {
        tripId,
        confirmationCode,
        passengerName
      },
    });

    dialogRef.afterClosed().subscribe(dob => {
      if (dob) {
        console.log(dob);
      }
    });
  }

  openTargetMessageModal(origin: string, destination: string, propName: string): void {
    this.dialog.open(TargetMessageModalComponent, {
      data: { origin, destination, propName }
    });
  }

  openLinkMinorModal() {
    const dialogRef = this.dialog.open(LinkMinorModalComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(routeToLinkMinor => routeToLinkMinor
      ? this.routerDispatchers.routeToLinkMinor(`${CHECKIN_ROUTES.ROUTE_PASSENGER.component}`)
      : this.openMinorTravelingAloneModal()
    );
  }

  openMinorTravelingAloneModal() {
    this.dialog.open(MinorTravelingAloneModalComponent, {});
  }

  openSeatUpgradeErrorModal(routeToSeats: boolean) {
    if (routeToSeats) {
      this.routerDispatchers.routeToSeats(CHECKIN_ROUTES.ROUTE_SEAT_UPGRADE_LIST.component);
    }

    const dialogRef = this.dialog.open(SeatUpgradeErrorModalComponent, {
    });

    dialogRef.afterClosed().subscribe(_ => {
      this.tripDispatchers.addToEliteStandByListErrorModalClosed();
    });
  }

  openMilitaryReferOutModal() {
    this.dialog.open(MilitaryReferOutModalComponent);
  }
}
