import { ModalContentsService } from '../modal-contents.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DynamicContentService } from '~app/services/dynamic-content.service';
import { ReferenceDataSelectors } from '../../store/services/reference-data/reference-data.selectors';
import { Observable } from 'rxjs';
import { AirportMap } from '~app/models/airport.model';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-target-message-modal',
  templateUrl: './target-message-modal.component.html',
  styleUrls: ['./target-message-modal.component.scss'],
})
@ContentSchema({
  description: 'Target Message Modal',
  name: 'TargetMessage',
  props: [
    { name: 'LAXinbound', type: PropertyType.Object },
    { name: 'LAXoutbound', type: PropertyType.Object },
    { name: 'SFOinbound', type: PropertyType.Object },
    { name: 'SFOoutbound', type: PropertyType.Object },
  ]
})
export class TargetMessageModalComponent implements OnInit {
  rendering: ComponentRendering;
  airports$: Observable<AirportMap>;
  contentMap: Object;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { origin: string, destination: string, propName: string },
    public dialogRef: MatDialogRef<TargetMessageModalComponent>,
    private modalContentsSvc: ModalContentsService,
    private dynamicContentService: DynamicContentService,
    private referenceDataSelectors: ReferenceDataSelectors,
  ) {
    this.airports$ = this.referenceDataSelectors.airports$;
  }

  ngOnInit() {
    this.rendering.props = this.modalContentsSvc.getRendering('target-message-modal') || [{}];
    this.airports$.pipe(take(1)).subscribe(airport => {
      this.contentMap = {
        originCityName: airport[this.data.origin].cityNm.split(', ').pop(),
        destinationCityName: airport[this.data.destination].cityNm.split(', ').pop(),
      };
    });
    this.rendering.props[this.data.propName].title = this.dynamicContentService.getContentString(
      this.rendering.props[this.data.propName].title, this.contentMap
    );
  }
}
