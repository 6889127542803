import { CHECKIN_ROUTES } from '~app/store/reducers/router/checkin-route-serializer';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { SessionData } from '~app/models/sessiondata.model';
import { Trip } from '~app/models/trip.model';
import { SegmentDetail } from '~app/models/segmentdetail.model';
import { Cart } from '~app/models/cart.model';
import { Response } from '~app/models/response.model';
import {
  SessionSelectors, SessionDispatchers, CartDispatchers, CartSelectors, RouterDispatchers, TripSelectors, OffersDispatchers, AnalyticsDispatchers
} from '~app/store';
import { combineLatest, Observable, Subscription, take } from 'rxjs';
import { Catalog1A } from '~app/models/catalog.model';
import { bagsIdentifiers, CATALOG_ID_BAG } from '~app/store/services/cart/cart.service';
import { ModalContentsService } from '~app/modals/modal-contents.service';
import { CartPassengerInfo } from "~app/models/cartpassengerinfo.model";
import { Product } from "~app/models/product.model";
import { OffersSelectors } from '~app/store/services/offers/offers.selectors';
import { BagOffers } from '~app/models/offer.model';
import { ModalsService } from '~app/modals/modals.service';
import { HelperService } from '~app/services/helper.service';
import { FooterButtons } from '~shared/checkin-footer/checkin-footer.component';
import { LaunchDarklyClientService } from '~app/services/launch-darkly-client.service';
import { FeatureFlagConstants } from '~app/constants/feature-flag-constants';

@Component({
  selector: 'app-bags',
  templateUrl: './bags.component.html',
  styleUrls: ['./bags.component.scss'],
})
@ContentSchema({
  description: 'BagsComponent',
  name: 'Bags',
  props: [
    { name: 'heading', type: PropertyType.Text },
    { name: 'regularBags', type: PropertyType.Object },
    { name: 'add', type: PropertyType.Text },
    { name: 'addSpecialItems', type: PropertyType.Text },
    { name: 'addRegularBags', type: PropertyType.Text },
    { name: 'description', type: PropertyType.Text },
    { name: 'edit', type: PropertyType.Text },
    { name: 'regularBagsHeader', type: PropertyType.Text },
    { name: 'overweightSpecialItemsHeader', type: PropertyType.Text },
    { name: 'specialItemsHeader', type: PropertyType.Text },
    { name: 'specialItemsHeading', type: PropertyType.Text },
    { name: 'specialItemsDescription', type: PropertyType.Text },
    { name: 'bagSizeDescription', type: PropertyType.Text },
    { name: 'bagWeightDescription', type: PropertyType.Text },
    { name: 'bagsDisclaimer', type: PropertyType.Text },
    { name: 'navButtons', type: PropertyType.Object }
  ]
})
export class BagsComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  sessionData: SessionData;
  infoLinksRendering: ComponentRendering;
  offersCatalogData: Catalog1A[];
  savingBags = false;
  catalogLoading = false;
  cart: Cart;
  sessionData$: Observable<SessionData>;
  loading$: Observable<boolean>;
  cart$: Observable<Response<Cart>>;
  cartLoading$: Observable<boolean>;
  tripLoading$: Observable<boolean>;
  priceQuotesLoading$: Observable<boolean>;
  bagOffers$: Observable<BagOffers>;
  offersLoading$: Observable<boolean>;
  trip2Checkin: Trip;
  bagsFooterButton: FooterButtons;
  bagsReceipt = {
    regularBags: {
      total: 0,
      quantity: 0,
    }
  };
  pax: SegmentDetail[] = [];
  sessionSub: Subscription = new Subscription();
  subscriptions: Subscription = new Subscription();
  isOARegularBagsEnabled: boolean;

  constructor(
    private sessionSelectors: SessionSelectors,
    private sessionDispatchers: SessionDispatchers,
    private cartDispatchers: CartDispatchers,
    private cartSelectors: CartSelectors,
    private routerDispatchers: RouterDispatchers,
    private modalContentsService: ModalContentsService,
    private tripSelectors: TripSelectors,
    private offersSelectors: OffersSelectors,
    private offersDispatchers: OffersDispatchers,
    private modalsService: ModalsService,
    private helperService: HelperService,
    private analyticsDispatchers: AnalyticsDispatchers,
    private ldClientService: LaunchDarklyClientService,
  ) {
    this.sessionData$ = this.sessionSelectors.session$;
    this.cart$ = this.cartSelectors.cart$;
    this.cartLoading$ = this.cartSelectors.cartLoading$;
    this.tripLoading$ = this.tripSelectors.tripLoading$;
    this.bagOffers$ = this.offersSelectors.bagOffers$;
    this.offersLoading$ = this.offersSelectors.bagOffersLoading$;
  }

  ngOnInit() {
    this.isOARegularBagsEnabled = this.ldClientService.getFlag(FeatureFlagConstants.WEB_CHECK_IN_SPA_OTHER_AIRLINE_REGULAR_BAGS_ENABLED);
    this.modalContentsService.addRendering('bags-price-confirmation-modal', this.rendering.components['bags-price-confirmation-modal']);
    this.infoLinksRendering = (this.rendering.components['info-links'] || [{}])[0];
    this.subscriptions.add(this.cartLoading$.subscribe(loading => this.savingBags = loading));
    this.subscriptions.add(this.cart$.subscribe(cart => this.cart = cart?.results?.[0]));
    this.subscriptions.add(this.offersSelectors.bagOffersState$.subscribe(offers => {
      if (this.cart) {
        if (offers.bagOffers) {
          this.offersCatalogData = offers.bagOffers?.catalogs?.filter(catalog => catalog.id === CATALOG_ID_BAG) || [];
        } else if (!offers.loading && !offers.error) {
          const items = (this.rendering.components['cart-item-content'])[0].props['cartItems']
            .map(contentItem => {
              return { code: contentItem.code, title: contentItem.title, icon: contentItem.icon, contentExists: true };
            });
          this.sessionDispatchers.addCartContent(items);
          this.offersDispatchers.getBagOffers();
          this.getCart();
        }
      }
    }));

    this.sessionSub = this.sessionData$.subscribe(sessionData => {
      sessionData ? this.sessionData = sessionData : this.sessionDispatchers.getState();
      this.sessionSub = combineLatest([this.sessionData$, this.bagOffers$]).subscribe(results => {
        if (this.sessionData && this.sessionData.selectedSegment && this.offersCatalogData) {
          this.trip2Checkin = this.sessionData.trip2Checkin;
          this.pax = this.sessionData.selectedSegment.details.filter(pax => this.sessionData.passengerIds.includes(pax.passengerId));
          this.pax = this.pax.map(p => {
            return {
              ...p,
              bagDetail: this.getBagDetail(p.passengerId),
              paxDetail: this.getPaxDetails(p),
              specialBagsCount: this.getSpecialBagsCount(p.passengerId)
            };
          });
        }
      });
      if (this.pax.length != 0) {
        this.sessionSub.unsubscribe();
      }
    });

    this.bagsFooterButton = {
      ...this.rendering?.props.navButtons,
      fees: this.sessionData.isOAFlight && !this.isOARegularBagsEnabled ? undefined : this.rendering?.props.navButtons?.fees,
    };

    this.analyticsDispatchers.baggagePageLoaded();
  }

  getBagDetail(paxId: string): Product {
    const offersDetail = this.offersCatalogData.find(object => object?.passengerProducts?.find(product => product?.dcsPassengerId === paxId))?.serviceDefinitions?.find(def => def?.productId === bagsIdentifiers.regularBag.id);
    return {
      productId: offersDetail?.id,
      attributes: offersDetail?.attributes,
      type: offersDetail?.type,
      maxPieces: +offersDetail?.maxQuantity,
    };
  }

  getPaxDetails(details: SegmentDetail): CartPassengerInfo {
    let journeyId = this.trip2Checkin.segments.entries.findIndex(segment => segment.id === this.sessionData.selectedSegment.id)
    const bagOffer = this.offersCatalogData[0].passengerProducts?.find(product => 
      product.dcsPassengerId === details?.passengerId && product.journeyId === journeyId?.toString()
    );
    return {
      journeyId: bagOffer?.journeyId,
      passengerId: bagOffer?.passengerId,
      segmentIds: this.getSegmentIds(),
      passengerSegmentIds: this.sessionData.selectedSegment.details.find(segDetail => segDetail.passengerId === details.passengerId)?.flightDetails?.map(flightDet => flightDet?.passengerSegmentId),
      passengerName: `${details.passengerName.firstName} ${details.passengerName.lastName}`,
      militaryTravelType: details.militaryTravelType,
      freeBagsCount: this.getFreeItems(details.passengerId),
      bagsTotalCost: this.getBagsTotalCostPerPax(bagOffer?.passengerId),
      alreadyAddedRegularBags: this.getPreviouslyAddedRegularBags(details.passengerId)
    };
  }

  private getSegmentIds(): string[] {
    let segmentIds: string[];
    this.sessionSelectors.sessionSelectedSegmentFlights$.pipe(take(1)).subscribe(segmentFlights =>
      segmentIds = segmentFlights.map(flight => this.helperService.getAmadeusStyleFlightId(flight))
    );
    return segmentIds;
  }

  getPreviouslyAddedRegularBags(paxId: string): number {
    let regularBagsCount = 0;
    const segment = this.trip2Checkin ? this.trip2Checkin.segments.entries
      .find(selection => selection.id === this.sessionData.selectedSegment.id) : null;
    const bags = segment ? segment.details.find(detail => detail.passengerId === paxId).bags : null;
    regularBagsCount =  bags ? bags.filter(bag => bag.productId === bagsIdentifiers.regularBag.id)
      .reduce((sum, current) => sum + Number(current.count), 0) : 0;
    return regularBagsCount;
  }

  getFreeItems(paxId: string): number {
    const freeItem = this.offersCatalogData[0].passengerProducts?.find(product => product.dcsPassengerId === paxId)?.products?.find(product => product.serviceDefinitionId.slice(-3) === bagsIdentifiers.freeItem.id).freeItemCount;
    return freeItem ? +freeItem : 0;
  }

  getCart(): void {
    this.subscriptions.add(this.sessionData$.subscribe(data => this.sessionData = data));
    this.cartDispatchers.getCart('bagsFormComponent', this.sessionData && this.sessionData.cartId);
    if (!!this.cart) {
      this.getBagsTotalCost();
    }
  }

  getBagsTotalCost(): void {
    this.bagsReceipt.regularBags.quantity = 0;
    this.bagsReceipt.regularBags.total = 0;
    this.cart?.items?.forEach(form => {
      if (form.productId === bagsIdentifiers.regularBag.id) {
        this.bagsReceipt.regularBags.quantity += Number(form.quantity);
        this.bagsReceipt.regularBags.total += Number(form.price);
      }
    });
  }

  getBagsTotalCostPerPax(paxId: string): number {
    const bags = this.cart ? this.cart.items.filter(item => item.associatedPassenger.passengerId === paxId &&
      item.catalogId === CATALOG_ID_BAG) : null;
      return bags ? bags.reduce((sum, current) => sum + Number(current.price), 0) : 0;
  }

  getSpecialBagsCount(paxId: string): number {
    const specialBags =  this.cart ? this.cart.items.filter(item => item.associatedPassenger.passengerId === paxId &&
      item.catalogId === CATALOG_ID_BAG && item.productId !== bagsIdentifiers.regularBag.id) : null;
    return specialBags ? specialBags.reduce((sum, current) => sum + Number(current.quantity), 0) : 0;
  }

  openBagSpecialItems(paxId: string): void {
    const param = {
      paxId: paxId
    };
    this.routerDispatchers.routeToBagSpecialItems(CHECKIN_ROUTES.ROUTE_BAGGAGE.component, { param });
  }

  // handles info link functions
  clickInfoLink(link: string): void {
    if (link === 'bagAdvisory') {
      this.routerDispatchers.routeToBagAdvisory(CHECKIN_ROUTES.ROUTE_BAGGAGE.component);
    } else if (link === 'military') {
        this.modalsService.openMilitaryReferOutModal();
    }
  }

  public ngOnDestroy(): void {
    this.offersCatalogData = null;
    this.sessionSub.unsubscribe();
    this.subscriptions.unsubscribe();
  }
}
